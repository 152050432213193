exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reading-boston-bookstores-js": () => import("./../../../src/pages/reading/boston-bookstores.js" /* webpackChunkName: "component---src-pages-reading-boston-bookstores-js" */),
  "component---src-pages-reading-favorites-js": () => import("./../../../src/pages/reading/favorites.js" /* webpackChunkName: "component---src-pages-reading-favorites-js" */),
  "component---src-pages-writing-bereal-js": () => import("./../../../src/pages/writing/bereal.js" /* webpackChunkName: "component---src-pages-writing-bereal-js" */),
  "component---src-pages-writing-classes-js": () => import("./../../../src/pages/writing/classes.js" /* webpackChunkName: "component---src-pages-writing-classes-js" */),
  "component---src-pages-writing-food-for-thought-js": () => import("./../../../src/pages/writing/food-for-thought.js" /* webpackChunkName: "component---src-pages-writing-food-for-thought-js" */),
  "component---src-pages-writing-liberal-arts-stem-js": () => import("./../../../src/pages/writing/liberal-arts-STEM.js" /* webpackChunkName: "component---src-pages-writing-liberal-arts-stem-js" */),
  "component---src-pages-writing-techforgood-js": () => import("./../../../src/pages/writing/techforgood.js" /* webpackChunkName: "component---src-pages-writing-techforgood-js" */)
}

